.about{
    background: #F6F8FE;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    &-content{
        padding: 6em 0;
        width: 30%;

        @media(max-width: 992px){
            width: 50%;
        }
        @media(max-width: 560px){
            width: 100%;
            padding: 4em 2em;
         }

        h1{
            font-weight: 700;
            font-size: 48px;
            line-height: 66px;
            text-align: center;
            letter-spacing: -0.03em;
            margin: 12px 0px;
            color: #000000;

            @media(max-width: 560px){
                font-size: 36px;
                line-height: 24px;
             }

        }

        p{
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            margin: 12px 0px;
            color: #4D4D4D;

        }
    }
}