.navbar{
    &-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin: 1em 1.5em 0 6em;

    @media(max-width: 560px){
        flex-direction: column;
        text-align: center;
        margin: 1.5em; 
        }
    h1{
        color: #2351DC;
        cursor: pointer;
        a{
            text-decoration: none;
            color: #2351DC;
        }
        
    }

    .ul-light{
        display: flex;
        align-items: center;
        

        &-project{
            border: 1px solid #2351DC;
            border-radius: 10px;
            padding: 8px 10px;
            cursor: pointer;

            &:hover{
                color: #fff;
                background: #2351DC;
            }
        }

        li{
            list-style: none;
            color: #111111;
            font-size: 1.2em;
            margin-left: 40px;
            font-weight: 400;

           
        }
    }

    }

    
    
}