.home{
    display: flex;
    background-color: #e9e5e0;
    padding: 2rem;
    height: 100vh;
    @media(max-width: 768px){
        padding: 1rem;
    }
    h1{
        color: #000;
        font-size: 8rem;
        font-weight: 500;
        line-height: 6.2rem;
        text-transform: capitalize;
        @media(max-width: 1400px){
            font-size: 6.8rem;
            line-height: 5.5rem;
        };
        @media(max-width: 992px){
            font-size: 6.8rem;
            line-height: 5.5rem;
        };
        @media(max-width: 768px){
            font-size: 3rem;
            line-height: 3rem;
        }
    }
    h3{
        margin: 1rem 0 1rem 0;
        font-size: 1.3rem;
        font-weight: 400;
        @media(max-width: 768px){
            font-size: 0.8rem;
            margin: 0;
        }
    }
    &-content{
        flex: 1.3;

        &-titles{
            margin-left: 6em;
            // width: 80%;
            height: 60vh;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;

            @media(max-width: 992px){
                height: 70vh;
                text-align: center;
            }

            @media(max-width: 560px){
                margin: 2em;
                height: 50vh;
             }
         

            h1{
                font-size: 48px;
                line-height: 72px;
                font-style: normal;
                font-weight: 900;
                line-height: 72px;
                margin: 6px 0px;
                letter-spacing: -0.03em;

                @media(max-width: 560px){
                    font-size: 36px;
                    line-height: 48px;
                 }
            }

            h3{
                margin: 12px 0px;
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                letter-spacing: 0.03em;
                color: #2351DC;

                @media(max-width: 560px){
                    font-size: 24px;
                    line-height: 36px;
                    margin: 6px 0px;
                 }
            }

            p{
                // font-weight: 300;
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0.03em;
                margin: 12px 0px;
                font-weight: 600;
                // color: #2351DC;

                @media(max-width: 560px){
                    font-size: 15px;
                    line-height: 24px;
                    margin: 6px 0px;
                 }
            }
        }
    }

    &-right{
        display: flex;
        justify-content: right;
        flex-direction: column;
    }

    &_skills{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: -5rem;

        @media(max-width: 769px){
            flex-direction: column;
            margin-top: -4rem;
         }

        &-card{
            background-color: #fff;
            border: 1px solid #e6ecf8;
            border-radius: 1rem;
            padding: 2.5rem;
            min-width: 300px;
            max-width: 400px;
            text-align: center;
            box-shadow: 7px 9px 5px -6px rgba(230,230,230,0.75);

            @media(max-width: 769px){
                margin-bottom: 20px;
                width: 320px;
                padding: 2em 1.2em;
             }

            h1{
                margin: 0.9em 0; 
                font-weight: 800;
                color: #2351DC;;
            }

            p{
                margin: 15px 0;
                line-height: 1.5;
                font-size: 1.25rem;
            }
        }
    }

    &-social{
        margin: 2em 0;

        img{
            margin-right: 1.5em;
        }
    }

    &-image{
        flex: 1;
        position: relative;

        &-handles{
            position: absolute;
            right: 10px;
            top: 10px;
        }
        &-text{
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 1rem;
            text-align: end;
        }

        &-div{
            width: 400px;
            height: 500px;
            // background-color: #EDF1FC;
            border: 2px solid #fff;
            position: absolute;
            right: 0;
            top: 0;
            margin: 1rem;
                      
            img{
                width: 100%;
                height: 100%;
            }
        
            @media(max-width: 1440px){
                width: 300px;
                height: 300px;
            }
            @media(max-width: 992px){
                width: 300px;
                height: 200px;
            }
        }
        @media(max-width: 992px){
            display: none
        }
       

    }

    // &_skills{
    //     margin: 6em;

    //     @media(max-width: 769px){
    //         margin: 4em 2em;
    //      }


    //     &-header{
    //         margin-bottom: 3em;

    //         @media(max-width: 769px){
    //             margin-bottom: 1em;
    //          }

    //         h1{
    //             font-weight: 700;
    //             font-size: 48px;
    //             line-height: 66px;
    //             letter-spacing: -0.03em;
    //             color: #000000;
    //             @media(max-width: 769px){
    //                 font-size: 36px;
    //                 line-height: 30px;
    //              }
    //         }
            
    //         p{
    //             font-style: normal;
    //             font-weight: 500;
    //             font-size: 18px;
    //             line-height: 28px;
    //         }

    //     }

    //     &-content{
    //         display: flex;
    //         flex-wrap: wrap;

    //         @media(max-width: 560px){
    //             flex-wrap: nowrap;
    //             flex-direction: column;
    //          }
            
    //     }

       
    // }

    &_skillTab{
        display: flex;
        align-items: center;
        margin-bottom: 2em;
        width: 50%;

        @media(max-width: 769px){
            width: 100%;
            margin-bottom: 1em;
         }

        &-logo{
            background: #EDF1FC;
            width: 80px;
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
        }

        &-bar{
            width: 100%;
            margin: 0 3em 0 1em;

            @media(max-width: 769px){
                margin: 0 1em;
             }
            

            p{
                margin-bottom: 10px;
            }
        }
    }

    &_project{
        padding: 6em;

        @media(max-width: 560px){
            margin: 4em 2em;
         }

        &-header{
            // text-align: center;
            margin-bottom: 3em;

            @media(max-width: 769px){
                margin-bottom: 1em;
             }

            h1{
                font-weight: 700;
                font-size: 8rem;
                line-height: 66px;
                letter-spacing: -0.03em;
                // color: #000000;

                @media(max-width: 769px){
                    font-size: 36px;
                    line-height: 30px;
                    margin-bottom: 10px;
                 }
            }
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
            }

        }
        &-content{
            margin: 4em 0;
        }
    }
    &_mockup{
        display: flex;
        align-items: center;
        margin-top: 4em;

        @media(max-width: 992px){
            flex-direction: column;
            margin-top: 2em;
         }

        &-care{
            img{
                width: 711px;
                height: 435px;
            }
            @media(max-width: 769px){
                width: 300px;
                height: 250px;
             }

             img{
                @media(max-width: 769px){
                    width: 100%;
                    height: 100%;
                 }
               
             }

        }

        &-images{
            width: 711px;
            height: 435px;
            // img{
            //     width: 100%;
            //     height: 100%;
            // }
            @media(max-width: 769px){
                width: 350px;
                height: 250px;
             }

             img{
                @media(max-width: 769px){
                    width: 100%;
                    height: 100%;
                 }
               
             }

        }

        &-content{
            h3{
                margin: 12px 0px;
                font-style: normal;
                font-weight: 800;
                font-size: 1.8em;
                line-height: 33px;
                letter-spacing: 0.03em;

            }

            p{
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.03em;
                margin: 12px 0px;

                a{
                    text-decoration: none;
                    color: "#000" !important;
                    cursor: pointer;
                    font-weight: 500;
                }
            }

            
        }
    }

    //footer
    &_footer{
        height: 403px;
        background: #07102C;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        width: 100%;

        @media(max-width: 560px){
            justify-content: flex-start;
         }

       

        &-icons{
            margin: 2em 0;
            @media(max-width: 560px){
                margin: 1em 0;
             }
            img{

                margin: 0 1em;
            }
        }
    }

  
}