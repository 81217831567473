*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

.home {
  display: flex;
  background-color: #e9e5e0;
  padding: 2rem;
  height: 100vh;
}
@media (max-width: 768px) {
  .home {
    padding: 1rem;
  }
}
.home h1 {
  color: #000;
  font-size: 8rem;
  font-weight: 500;
  line-height: 6.2rem;
  text-transform: capitalize;
}
@media (max-width: 1400px) {
  .home h1 {
    font-size: 6.8rem;
    line-height: 5.5rem;
  }
}
@media (max-width: 992px) {
  .home h1 {
    font-size: 6.8rem;
    line-height: 5.5rem;
  }
}
@media (max-width: 768px) {
  .home h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
}
.home h3 {
  margin: 1rem 0 1rem 0;
  font-size: 1.3rem;
  font-weight: 400;
}
@media (max-width: 768px) {
  .home h3 {
    font-size: 0.8rem;
    margin: 0;
  }
}
.home-content {
  flex: 1.3;
}
.home-content-titles {
  margin-left: 6em;
  height: 60vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
@media (max-width: 992px) {
  .home-content-titles {
    height: 70vh;
    text-align: center;
  }
}
@media (max-width: 560px) {
  .home-content-titles {
    margin: 2em;
    height: 50vh;
  }
}
.home-content-titles h1 {
  font-size: 48px;
  line-height: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: 72px;
  margin: 6px 0px;
  letter-spacing: -0.03em;
}
@media (max-width: 560px) {
  .home-content-titles h1 {
    font-size: 36px;
    line-height: 48px;
  }
}
.home-content-titles h3 {
  margin: 12px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.03em;
  color: #2351DC;
}
@media (max-width: 560px) {
  .home-content-titles h3 {
    font-size: 24px;
    line-height: 36px;
    margin: 6px 0px;
  }
}
.home-content-titles p {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.03em;
  margin: 12px 0px;
  font-weight: 600;
}
@media (max-width: 560px) {
  .home-content-titles p {
    font-size: 15px;
    line-height: 24px;
    margin: 6px 0px;
  }
}
.home-right {
  display: flex;
  justify-content: right;
  flex-direction: column;
}
.home_skills {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: -5rem;
}
@media (max-width: 769px) {
  .home_skills {
    flex-direction: column;
    margin-top: -4rem;
  }
}
.home_skills-card {
  background-color: #fff;
  border: 1px solid #e6ecf8;
  border-radius: 1rem;
  padding: 2.5rem;
  min-width: 300px;
  max-width: 400px;
  text-align: center;
  box-shadow: 7px 9px 5px -6px rgba(230, 230, 230, 0.75);
}
@media (max-width: 769px) {
  .home_skills-card {
    margin-bottom: 20px;
    width: 320px;
    padding: 2em 1.2em;
  }
}
.home_skills-card h1 {
  margin: 0.9em 0;
  font-weight: 800;
  color: #2351DC;
}
.home_skills-card p {
  margin: 15px 0;
  line-height: 1.5;
  font-size: 1.25rem;
}
.home-social {
  margin: 2em 0;
}
.home-social img {
  margin-right: 1.5em;
}
.home-image {
  flex: 1;
  position: relative;
}
.home-image-handles {
  position: absolute;
  right: 10px;
  top: 10px;
}
.home-image-text {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
  text-align: end;
}
.home-image-div {
  width: 400px;
  height: 500px;
  border: 2px solid #fff;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1rem;
}
.home-image-div img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1440px) {
  .home-image-div {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 992px) {
  .home-image-div {
    width: 300px;
    height: 200px;
  }
}
@media (max-width: 992px) {
  .home-image {
    display: none;
  }
}
.home_skillTab {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  width: 50%;
}
@media (max-width: 769px) {
  .home_skillTab {
    width: 100%;
    margin-bottom: 1em;
  }
}
.home_skillTab-logo {
  background: #EDF1FC;
  width: 80px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.home_skillTab-bar {
  width: 100%;
  margin: 0 3em 0 1em;
}
@media (max-width: 769px) {
  .home_skillTab-bar {
    margin: 0 1em;
  }
}
.home_skillTab-bar p {
  margin-bottom: 10px;
}
.home_project {
  padding: 6em;
}
@media (max-width: 560px) {
  .home_project {
    margin: 4em 2em;
  }
}
.home_project-header {
  margin-bottom: 3em;
}
@media (max-width: 769px) {
  .home_project-header {
    margin-bottom: 1em;
  }
}
.home_project-header h1 {
  font-weight: 700;
  font-size: 8rem;
  line-height: 66px;
  letter-spacing: -0.03em;
}
@media (max-width: 769px) {
  .home_project-header h1 {
    font-size: 36px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
.home_project-header p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}
.home_project-content {
  margin: 4em 0;
}
.home_mockup {
  display: flex;
  align-items: center;
  margin-top: 4em;
}
@media (max-width: 992px) {
  .home_mockup {
    flex-direction: column;
    margin-top: 2em;
  }
}
.home_mockup-care img {
  width: 711px;
  height: 435px;
}
@media (max-width: 769px) {
  .home_mockup-care {
    width: 300px;
    height: 250px;
  }
}
@media (max-width: 769px) {
  .home_mockup-care img {
    width: 100%;
    height: 100%;
  }
}
.home_mockup-images {
  width: 711px;
  height: 435px;
}
@media (max-width: 769px) {
  .home_mockup-images {
    width: 350px;
    height: 250px;
  }
}
@media (max-width: 769px) {
  .home_mockup-images img {
    width: 100%;
    height: 100%;
  }
}
.home_mockup-content h3 {
  margin: 12px 0px;
  font-style: normal;
  font-weight: 800;
  font-size: 1.8em;
  line-height: 33px;
  letter-spacing: 0.03em;
}
.home_mockup-content p {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.03em;
  margin: 12px 0px;
}
.home_mockup-content p a {
  text-decoration: none;
  color: "#000" !important;
  cursor: pointer;
  font-weight: 500;
}
.home_footer {
  height: 403px;
  background: #07102C;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
@media (max-width: 560px) {
  .home_footer {
    justify-content: flex-start;
  }
}
.home_footer-icons {
  margin: 2em 0;
}
@media (max-width: 560px) {
  .home_footer-icons {
    margin: 1em 0;
  }
}
.home_footer-icons img {
  margin: 0 1em;
}

.about {
  background: #F6F8FE;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.about-content {
  padding: 6em 0;
  width: 30%;
}
@media (max-width: 992px) {
  .about-content {
    width: 50%;
  }
}
@media (max-width: 560px) {
  .about-content {
    width: 100%;
    padding: 4em 2em;
  }
}
.about-content h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 66px;
  text-align: center;
  letter-spacing: -0.03em;
  margin: 12px 0px;
  color: #000000;
}
@media (max-width: 560px) {
  .about-content h1 {
    font-size: 36px;
    line-height: 24px;
  }
}
.about-content p {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin: 12px 0px;
  color: #4D4D4D;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin: 1em 1.5em 0 6em;
}
@media (max-width: 560px) {
  .navbar-header {
    flex-direction: column;
    text-align: center;
    margin: 1.5em;
  }
}
.navbar-header h1 {
  color: #2351DC;
  cursor: pointer;
}
.navbar-header h1 a {
  text-decoration: none;
  color: #2351DC;
}
.navbar-header .ul-light {
  display: flex;
  align-items: center;
}
.navbar-header .ul-light-project {
  border: 1px solid #2351DC;
  border-radius: 10px;
  padding: 8px 10px;
  cursor: pointer;
}
.navbar-header .ul-light-project:hover {
  color: #fff;
  background: #2351DC;
}
.navbar-header .ul-light li {
  list-style: none;
  color: #111111;
  font-size: 1.2em;
  margin-left: 40px;
  font-weight: 400;
}/*# sourceMappingURL=main.css.map */